import React from 'react';

export default class BlankPreview extends React.Component {
	render() {
		return (
			<div className="blank-preview">
				<div className="blank-content">
					<i className="material-icons icon">keyboard</i>
					<h3>Make something</h3>
				</div>
			</div>
		)
	}
}