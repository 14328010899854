const AppConfig = {
	firebase: {
		"apiKey": "AIzaSyCJTMXtKhYdeTmnqehxjI4M-Y0f3224Bto",
		"authDomain": "agroapp-95253.firebaseapp.com",
		"databaseURL": "https://agroapp-95253.firebaseio.com",
		"messagingSenderId": "1003832752738",
		"projectId": "agroapp-95253",
		"storageBucket": "agroapp-95253.appspot.com"
	},
	dev: false
};

export default AppConfig;